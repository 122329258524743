import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

const BackButton = ({ text, to }) => {
  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-[#FFFFFFBF] shadow-md p-3 flex">
      <Link to={to}>
        <IoIosArrowBack className="text-[#007AFF] text-3xl " />
      </Link>
      <div className="text-[#000000] font-[590] text-xl text-center mx-auto">
        {text}
      </div>
    </div>
  );
};
BackButton.propTypes = {
  text: PropTypes.string.isRequired, // Prop 'text'  string types and required
  to: PropTypes.string.isRequired, // Prop 'to'  string types and required
};

export default BackButton;
