import React from 'react';
import PropTypes from 'prop-types';

const ParentForm = ({ registerData, setRegisterData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };
  return (
    <div className="mt-4">
      <div className="font-[700] text-[#151515]">
        Particulars of Parent / Guardian
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Name of Parent/ Guardian
        </label>
        <input
          type="text"
          id="parent_name"
          name="parent_name"
          value={registerData.parent_name}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border  py-3 px-4 text-sm rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Email Address
        </label>
        <input
          type="text"
          id="email"
          name="email"
          value={registerData.email}
          onChange={handleInputChange}
          className="w-full mt-1 p-2  py-3 px-4 text-sm border  rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Contact No: (Father)
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center mt-1 pl-2 font-[500] text-sm text-[#151515]">
            +65
          </div>
          <input
            type="number"
            id="father_contact"
            name="father_contact"
            value={registerData.father_contact}
            onChange={handleInputChange}
            className="w-full pl-10 mt-1 p-2 py-3 border text-sm rounded-xl border-[#E2E8F0] focus:outline-none"
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Contact No: (Mother)
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center mt-1 pl-2 font-[500] text-sm text-[#151515]">
            +65
          </div>
          <input
            type="number"
            id="mother_contact"
            name="mother_contact"
            value={registerData.mother_contact}
            onChange={handleInputChange}
            className="w-full pl-10 mt-1 p-2 py-3 border text-sm rounded-xl border-[#E2E8F0] focus:outline-none"
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Contact No: (Home)
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center mt-1 pl-2 font-[500] text-sm text-[#151515]">
            +65
          </div>
          <input
            type="number"
            id="home_contact"
            name="home_contact"
            value={registerData.home_contact}
            onChange={handleInputChange}
            className="w-full pl-10 mt-1 p-2 py-3 border text-sm rounded-xl border-[#E2E8F0] focus:outline-none"
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Contact No: (Guardian)
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center mt-1  pl-2 font-[500] text-sm text-[#151515]">
            +65
          </div>
          <input
            type="number"
            id="guardian_contact"
            name="guardian_contact"
            value={registerData.guardian_contact}
            onChange={handleInputChange}
            className="w-full pl-10 mt-1 p-2 py-3 border text-sm rounded-xl border-[#E2E8F0] focus:outline-none"
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Please Indicate First-person-to-contact above
        </label>
        <input
          type="text"
          id="first_person_contact"
          name="first_person_contact"
          value={registerData.first_person_contact}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl  border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="text-sm font-[500] text-[#373737] mt-2">
        I acknowledge that I have read and understood the contents of the letter
        <span className="text-xs">
          {' '}
          (Ref: HIPS2023-10-01 dtd 16 Oct 2023).{' '}
        </span>
        I also agree and accept to the payment terms and conditions.
      </div>
      <label className="flex items-center mt-4 ml-4">
        <input
          type="radio"
          className="form-radio  text-[#007AFF] h-4 w-4"
          name="agreement"
          value="I Consent"
          checked={registerData.agreement === 'I Consent'}
          onChange={handleInputChange}
        />
        <span className="ml-2 text-[#151515] font-[500] text-sm">
          I consent.
        </span>
      </label>
    </div>
  );
};
ParentForm.propTypes = {
  registerData: PropTypes.object.isRequired,
  setRegisterData: PropTypes.func.isRequired,
};

export default ParentForm;
