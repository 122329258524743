import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="text-center font-[700] mt-4 text-sm">Privacy Policy</div>
      <div className="font-[400] text-sm mt-2">
        This Data Protection Notice (“Notice”) sets out the basis which MIN YANG
        TRADING AND TRANSPORT SERVICES PTE LTD (“we”, “us”, or “our”) may
        collect, use, disclose or otherwise process personal data of our
        customers in accordance with the Personal Data Protection Act (“PDPA”).
        This Notice applies to personal data in our possession or under our
        control, including personal data in the possession of organisations
        which we have engaged to collect, use, disclose or process personal data
        for our purposes.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
