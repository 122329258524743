import React from 'react';
import { BiSolidCircle } from 'react-icons/bi';

const Header = () => {
  return (
    <div className="flex justify-between mt-20 px-6 mx-8 bg-[#FFFFFFBF] shadow-xl rounded-2xl py-6">
      <div className="flex">
        <div className="mt-2 mr-4">
          <BiSolidCircle className="text-4xl text-[#34C759]" />
        </div>
        <div>
          <div className="font-[700]">Particulars of Student</div>
          <div className="text-[#37373780] font-[500] text-sm ">
            All details of studentst
          </div>
        </div>
      </div>
      <div className="border-b border-dashed border-[#37373780] h-7 w-[10%] mx-2"></div>
      <div className="flex">
        <div className="mt-2 mr-4">
          <BiSolidCircle className="text-4xl text-[#37373780]" />
        </div>
        <div>
          <div className="font-[700]">Bus Transport Service</div>
          <div className="text-[#37373780] font-[500] text-sm">
            Services required
          </div>
        </div>
      </div>
      <div className="border-b border-dashed border-[#37373780] h-7 w-[10%] mx-2"></div>
      <div className="flex">
        <div className="mt-2 mr-4">
          <BiSolidCircle className="text-4xl text-[#37373780]" />
        </div>
        <div>
          <div className="font-[700]">Particulars of Parent / Guardian</div>
          <div className="text-[#37373780] font-[500] text-sm ">
            All details of parent / guardian
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
