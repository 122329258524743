import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackButton from '../components/Navbar/BackButton';
import StudentForm from '../components/RegisterForms/StudentForm';
import ServicesForm from '../components/RegisterForms/ServicesForm';
import ParentForm from '../components/RegisterForms/ParentForm';
import axios from 'axios';
import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import Header from '../components/RegisterForms/Header';

const RegisterPage = () => {
  let [searchParams] = useSearchParams();
  const schoolIdString = searchParams.get('school_id') || null;
  const schoolId = schoolIdString ? parseInt(schoolIdString, 10) : null;
  
  const [registerData, setRegisterData] = useState({
    name: '',
    birth: '',
    gender: '',
    class: '',
    address: '',
    postalcode_address: '',
    sibling_names: [],
    service: '',
    pickup_address: '',
    postalcode_pickup: '',
    dropoff_address: '',
    postalcode_dropoff: '',
    same_pickupaddress: false,
    parent_name: '',
    email: '',
    father_contact: '',
    mother_contact: '',
    home_contact: '',
    guardian_contact: '',
    first_person_contact: '',
    agreement: '',
    school_id: schoolId,
    reference: '',
  });

  const [validate, setValidate] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // get data school by schoolID
    try {
      const schoolResponse = await axios({
        // eslint-disable-next-line no-undef
        url: `${process.env.REACT_APP_API_URL}/schools/${schoolId}`,
        method: 'GET',
      });

      if (schoolResponse.data && schoolResponse.data.data) {
        // get data school_code from data school
        const schoolCode = schoolResponse.data.data.school_code;

        // Check if pickup_address is empty and set it to address
        if (!registerData.pickup_address) {
          registerData.pickup_address = registerData.address;
        }
        // Check if postcode_pickup is empty and set it to postal code address
        if (!registerData.postalcode_pickup) {
          registerData.postalcode_pickup = registerData.postalcode_address;
        }

        // Check if dropoff_address is empty and set it to pickup_address
        if (!registerData.dropoff_address) {
          registerData.dropoff_address = registerData.pickup_address;
        }

        // Check if postalcode dropoff is empty and set it to postalcod pickup
        if (!registerData.postalcode_dropoff) {
          registerData.postalcode_dropoff = registerData.postalcode_pickup;
        }

        // format reference
        const birth = registerData.birth;
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const reference = `#${year}${month}${currentDate.getDate()}-${schoolCode}-${birth}`;

        // Prepare data to be sent
        const dataToSend = {
          ...registerData,
          reference: reference,
        };

        // Send data to the server
        const response = await axios({
          // eslint-disable-next-line no-undef
          url: `${process.env.REACT_APP_API_URL}/users`,
          method: 'POST',
          data: dataToSend,
        });

        if (response.data && response.data.message) {
          const userId = response.data.data.id;
          setSuccessMessage(response.data.message);
          
          // Navigate to success page after a while
          setTimeout(() => {
            navigate(`/success/${userId}`);
          }, 1000);
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setValidate(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lg:w-[90%] lg:mx-auto font-sans">
      <BackButton text="Register" to={`/terms?school_id=${schoolId}`} />
      <div className="hidden lg:block">
        <Header />
      </div>
      <div className="px-6">
        <form onSubmit={handleFormSubmit}>
          <div className="lg:flex lg:flex-row lg:justify-center lg:mx-auto">
            <div className="lg:mt-10 mt-20 lg:mx-12 lg:w-1/3 border-b border-[#B8B8B8] pb-6 lg:border-r lg:border-b-0 lg:pb-0 lg:pr-16 ">
              <StudentForm
                registerData={registerData}
                setRegisterData={setRegisterData}
              />
            </div>
            <div className="lg:mt-10  lg:w-1/3 border-b border-[#B8B8B8] pb-6 lg:border-r lg:border-b-0 lg:pb-0 lg:pr-12">
              <ServicesForm
                registerData={registerData}
                setRegisterData={setRegisterData}
              />
            </div>
            <div className="lg:mt-10 lg:mx-12 lg:w-1/3  ">
              <ParentForm
                registerData={registerData}
                setRegisterData={setRegisterData}
              />
            </div>
          </div>
          <div className="mt-4">
            {validate.length > 0 && (
              <div className="bg-pink-200 text-red-700 p-4 mx-auto">
                {validate.map((message, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <span className="mr-2">
                      <FaExclamationCircle />
                    </span>
                    <span>{message}</span>
                  </div>
                ))}
              </div>
            )}
            {successMessage && (
              <div className="bg-green-200 text-green-700 p-4 mx-auto mt-4 flex items-center">
                <FaCheckCircle className="mr-2 text-2xl" />
                <span>{successMessage}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center items-center mt-4">
            <button
              className="p-2 rounded-full bg-[#FFCC00] text-[#373737] px-6  font-[500] text-lg"
              type="submit"
            >
              Submit
            </button>
            <div className="mt-4 text-[#444342] mb-3 font-[400] text-sm ">
              Powered by <span className="text-[#F15A29]"> i</span>Zee
              <span className="text-[#F15A29]">M</span> Pte Ltd
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RegisterPage;
