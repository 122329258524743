import React, { useEffect, useState } from 'react';
import logoImage from '../assets/images/logo.svg';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

const SuccessRegisterPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    axios
      // eslint-disable-next-line no-undef
      .get(`${process.env.REACT_APP_API_URL}/users/${id}`)
      .then((response) => {
        const createdAtDate = parseISO(response.data.data.createdAt);

        // change format
        const formattedDate = format(createdAtDate, 'd MMM yyyy, HH:mm');

        // state dengan data yang telah diformat
        setUserData({
          ...response.data.data,
          createdAt: formattedDate,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);
  return (
    <div className="h-screen text-black flex flex-col justify-center items-center bg-landing-page bg-cover bg-center font-sans">
      <div className="bg-white bg-opacity-50 backdrop-blur-xl lg:backdrop-blur-md  h-full lg:h-[85%] w-full lg:w-[85%]  lg:rounded-3xl flex flex-col justify-center items-center  ">
        <div className="hidden lg:block">
          <div className="flex flex-col justify-center items-center">
            <img src={logoImage} alt="Logo" className="h-25 w-25" />
            <div className="text-center text-[#334672] font-[700] w-[60%] ">
              MIN YANG TRADING AND TRANSPORT SERVICES PTE LTD
            </div>
          </div>
        </div>
        <div className="text-center text-[#151515] font-[700] mx-5 text-2xl lg:mt-8">
          Thank You for Your Registration
        </div>
        <div className="w-[85%]">
          <div className="mt-10 text-center text-[#373737] font-[500]">
            Submitted on {userData.createdAt} Your reference is{' '}
            {userData.reference}
          </div>
        </div>
        <div className="w-[90%]">
          <div className="text-[#373737] font-[500] text-sm mt-10 text-center">
            An email has been sent to{' '}
            <span className="text-[#007AFF]">{userData.email}</span> to confirm
            your interest in the school bus service.
          </div>
          <div className="text-[#373737] font-[500] text-sm mt-4 text-center">
            You will receive a notification for confirmation and payment closer
            to the school start date.
          </div>
        </div>
        <Link to="/">
          <button className="bg-[#FFCC00] text-[#373737] text-lg font-[500] px-5 py-2 rounded-full mt-12">
            Okay
          </button>
        </Link>
        <div className="hidden lg:block">
          <div className="text-[#444342] mb-3 font-[400] text-sm mt-8 ">
            Powered by <span className="text-[#F15A29]"> i</span>Zee
            <span className="text-[#F15A29]">M</span> Pte Ltd
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegisterPage;
