import React from 'react';
import PropTypes from 'prop-types';
import { BsTrash } from 'react-icons/bs';

const StudentForm = ({ registerData, setRegisterData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'sibling_names') {
      setRegisterData({
        ...registerData,
        sibling_names: [{ name: value }],
      });
    } else {
      setRegisterData({
        ...registerData,
        [name]: value,
      });
    }
  };

  const handleAddSibling = (event) => {
    event.preventDefault();
    setRegisterData((prevData) => {
      const newSibling = { name: '' };
      const updatedSiblings = [...prevData.sibling_names, newSibling];
      return { ...prevData, sibling_names: updatedSiblings };
    });
  };

  const handleRemoveSibling = (index) => {
    const updatedSiblings = [...registerData.sibling_names];
    updatedSiblings.splice(index, 1);
    setRegisterData({
      ...registerData,
      sibling_names: updatedSiblings,
    });
  };

  return (
    <div className="mt-4">
      <div className="font-[700] text-[#151515]">Particulars of Student</div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Name of Student
          <span className="text-[#37373780]">
            {' '}
            (in full, as of birth cert.)
          </span>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={registerData.name}
          onChange={handleInputChange}
          className="w-full mt-1 p-2  py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Birth Cert No
          <span className="text-[#37373780]"> (last 4 digit)</span>
        </label>
        <div className="flex flex-row justify-between mt-1">
          <div className="text-[#151515] font-[500] mt-2">T X X X</div>
          <input
            type="number"
            id="birth"
            name="birth"
            value={registerData.birth}
            onChange={handleInputChange}
            className="w-3/5 p-2  py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Gender
          <span className="text-[#37373780]"> (*delete accordingly)</span>
        </div>
        <div className="flex items-center mt-2 ml-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-checkbox  text-[#007AFF] h-4 w-4"
              name="gender"
              value="MALE"
              checked={registerData.gender === 'MALE'}
              onChange={handleInputChange}
            />
            <span className="ml-2 text-[#151515] font-[500] text-sm">Male</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input
              type="radio"
              className="form-checkbox text-[#007AFF] h-4 w-4"
              name="gender"
              value="FEMALE"
              checked={registerData.gender === 'FEMALE'}
              onChange={handleInputChange}
            />
            <span className="ml-2 text-[#151515] font-[500] text-sm">
              Female
            </span>
          </label>
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <div className="text-sm font-[500] text-[#373737] mt-2">
          Class (2024)
        </div>
        <div className="relative w-3/5">
          <select
            id="class"
            name="class"
            value={registerData.class}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-xl border-[#E2E8F0] focus:outline-none "
          >
            <option value="" disabled>
              Select Class
            </option>
            <option value="Kindergarten">Kindergarten</option>
            <option value="Primary">Primary</option>
          </select>
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Residential Home Address
          <span className="text-[#37373780]"> (in full)</span>
        </label>
        <input
          type="text"
          id="address"
          name="address"
          value={registerData.address}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none "
        />
      </div>
      <div className="flex flex-row justify-between mt-4">
        <label className="text-sm font-[500] text-[#373737] mt-2">
          Postal Code:
        </label>
        <input
          type="number"
          id="postalcode_address"
          name="postalcode_address"
          value={registerData.postalcode_address}
          onChange={handleInputChange}
          className="w-3/5 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Name of Sibling taking the School Transport in this School
          <span className="text-[#37373780]"> (if any)</span>
        </label>
        <input
          type="text"
          name={`additional_sibling_0`}
          value={
            (registerData.sibling_names[0] &&
              registerData.sibling_names[0].name) ||
            ''
          }
          onChange={(e) => {
            const updatedSiblings = [...registerData.sibling_names];
            if (!updatedSiblings[0]) {
              updatedSiblings[0] = { name: '' };
            }
            updatedSiblings[0].name = e.target.value;
            setRegisterData({
              ...registerData,
              sibling_names: updatedSiblings,
            });
          }}
          className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
        {registerData.sibling_names.slice(1).map((sibling, index) => (
          <div key={index} className="relative mt-4">
            <input
              type="text"
              name={`additional_sibling_${index + 1}`}
              value={sibling.name}
              onChange={(e) => {
                const updatedSiblings = [...registerData.sibling_names];
                if (!updatedSiblings[index + 1]) {
                  updatedSiblings[index + 1] = { name: '' };
                }
                updatedSiblings[index + 1].name = e.target.value;
                setRegisterData({
                  ...registerData,
                  sibling_names: updatedSiblings,
                });
              }}
              placeholder="(Additional Sibling)"
              className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
            />
            <button
              type="button"
              onClick={() => handleRemoveSibling(index + 1)}
              className="absolute right-0 mt-3 mr-2 py-1 px-2 text-[#E2E2E2] text-lg rounded-full"
            >
              <BsTrash />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddSibling}
          className="bg-[#E2E2E2] mt-2 py-3 px-3 mx-auto w-full text-[#373737] text-sm font-[500] rounded-xl"
        >
          Add Additional Sibling
        </button>
      </div>
    </div>
  );
};
StudentForm.propTypes = {
  registerData: PropTypes.object.isRequired,
  setRegisterData: PropTypes.func.isRequired,
};

export default StudentForm;
