import React from 'react';

const Information = () => {
  return (
    <div className="mt-16 ">
      <ol className="list-decimal text-[#373737] text-sm font-[400]">
        <li>
          Please submit this Registration Form to the School Bus Operator by
          <span className="font-[700]"> 27 Oct 2023.</span>
        </li>
        <li className="mt-2">
          Late registration may result in your child not being able to secure
          the service if the planned route is full.
        </li>
        <li className="mt-2">
          This is not a bus service confirmation. You will be advised separately
          by the transport company.
        </li>
        <li className="mt-2">
          Contact Details:
          <div className="flex flex-row mt-1">
            <div className="mr-4">Tel: 6288 8298</div>
            <div className="ml-16">Fax: 6282 2298</div>
          </div>
          <div>WhatsApp: 9736 8298</div>
          <div>
            Email:{' '}
            <span className="text-[#007AFF]">info@myservices.com.sg</span>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default Information;
