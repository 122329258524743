import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { RiChatDeleteFill } from 'react-icons/ri';

const ButtonTerms = () => {
  let [searchParams] = useSearchParams();
  const schoolId = searchParams.get('school_id') || null;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeclineClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={handleModalClose}
          ></div>
          <div className="bg-white p-8 rounded-lg shadow-lg justify-center z-50 relative">
            <RiChatDeleteFill className="mx-auto text-[4rem] text-[#FF3B30FC]" />
            <p className="font-[500] text-sm mx-auto">
              Form requires acceptance of privacy policy. For questions, contact
              us at [Client Contact Email Address].
            </p>
          </div>
          <div className="mt-4 z-50 relative">
            <button
              className="px-4 py-2 bg-[#FFCC00] text-[#373737] rounded-lg"
              onClick={handleModalClose}
            >
              Okay
            </button>
          </div>
        </div>
      )}

      <div>
        <div className="flex flex-row mt-8 justify-center">
          <div
            className="text-[#FF3B30] p-2 mx-4 font-[400] text-lg cursor-pointer"
            onClick={handleDeclineClick}
          >
            Decline
          </div>
          <Link to={`/register?school_id=${schoolId}`}>
            <button className="p-2 rounded-full bg-[#34C759] text-white px-6 mx-4 font-[500] text-lg">
              Agree
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ButtonTerms;
