import React, { useState, useEffect } from 'react';
import logoImage from '../assets/images/logo.svg';
import { FaExclamationCircle } from 'react-icons/fa';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const LandingPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let [validSchool, setValidSchool] = useState(true);

  const schoolId = searchParams.get('school_id');
  const localStorageSchoolId = localStorage.getItem('school_id');

  const validateSchool = async () => {
    try {
      if (!schoolId) {
        if (localStorageSchoolId) {
          setSearchParams((params) => {
            params.set('school_id', localStorageSchoolId);
            return params;
          });
        } else {
          setSearchParams((params) => {
            params.set('school_id', 1);
            localStorage.setItem('school_id', 1);
            return params;
          });
        }
      } else if (schoolId) {
        console.log(localStorage.getItem('school_id'));
        const response = await axios({
          // eslint-disable-next-line no-undef
          url: `${process.env.REACT_APP_API_URL}/schools/${schoolId}`,
          method: 'GET',
        });
        if (!response.data) {
          setValidSchool(false);
        }
        localStorage.setItem('school_id', schoolId);
        console.log(localStorage.getItem('school_id'));
      }
    } catch (error) {
      setValidSchool(false);
    }
  };

  useEffect(() => {
    validateSchool();
  }, []);

  return (
    <div className="h-screen text-black flex flex-col justify-center items-center bg-landing-page bg-cover bg-center font-sans">
      <div className="bg-white bg-opacity-50 backdrop-blur-md h-[85%] w-[85%] rounded-3xl  flex flex-col justify-center items-center">
        <img src={logoImage} alt="Logo" className="h-25 w-25" />
        <div className="text-center text-[#334672] font-[700] mx-5 text-base">
          MIN YANG TRADING AND TRANSPORT SERVICES PTE LTD
        </div>
        <div className="mt-10 text-[#373737] font-[500] text-base">
          We prioritize your childs safety
        </div>
        <Link to={`/terms?school_id=${schoolId}`}>
          <button
            disabled={!validSchool}
            className="bg-[#FFCC00] text-[#373737] text-lg font-[500] px-4 py-2 rounded-full mt-8"
          >
            Register
          </button>
        </Link>
        <div className="mt-4">
          {!validSchool && (
            <div className="bg-pink-200 text-red-700 p-4 mx-auto">
              <div className="flex items-center mb-2">
                <span className="mr-2">
                  <FaExclamationCircle />
                </span>
                <span>{`School with ID ${schoolId} does not exist.`}</span>
              </div>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 text-[#444342] mb-3 font-[400] text-sm ">
          Powered by <span className="text-[#F15A29]"> i</span>Zee
          <span className="text-[#F15A29]">M</span> Pte Ltd
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
