import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  LandingPage,
  RegisterPage,
  SuccessRegisterPage,
  TermsConditionsPage,
} from './pages';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms" element={<TermsConditionsPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/success/:id" element={<SuccessRegisterPage />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
