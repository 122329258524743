import React from 'react';
import PropTypes from 'prop-types';

const ServicesForm = ({ registerData, setRegisterData }) => {
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setRegisterData({
      ...registerData,
      [name]: name === 'same_pickupaddress' ? checked : value,
    });
  };
  return (
    <div className="mt-4">
      <div className="font-[700] text-[#151515]">Bus Transport Service</div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Service Required:
        </div>
        <label className="flex items-center mt-4 ml-4">
          <input
            type="radio"
            className="form-radio  text-[#007AFF] h-4 w-4"
            name="service"
            value="1-way to school"
            checked={registerData.service === '1-way to school'}
            onChange={handleInputChange}
          />
          <span className="ml-2 text-[#151515] font-[500] text-sm">
            1-way to school
          </span>
        </label>
        <label className="flex items-center mt-4 ml-4">
          <input
            type="radio"
            className="form-radio  text-[#007AFF] h-4 w-4"
            name="service"
            value="1-way from school"
            checked={registerData.service === '1-way from school'}
            onChange={handleInputChange}
          />
          <span className="ml-2 text-[#151515] font-[500] text-sm">
            1-way from school
          </span>
        </label>
        <label className="flex items-center mt-4 ml-4">
          <input
            type="radio"
            className="form-radio  text-[#007AFF] h-4 w-4"
            name="service"
            value="2-way transport"
            checked={registerData.service === '2-way transport'}
            onChange={handleInputChange}
          />
          <span className="ml-2 text-[#151515] font-[500] text-sm">
            2-way transport
          </span>
        </label>
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Pick-up Address to School
          <span className="text-[#37373780]">
            (if different from residential home address)
          </span>
        </label>
        <input
          type="text"
          id="pickup_address"
          name="pickup_address"
          value={registerData.pickup_address}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="flex flex-row justify-between mt-4">
        <label className="text-sm font-[500] text-[#373737] mt-2">
          Postal Code:
        </label>
        <input
          type="number"
          id="postalcode_pickup"
          name="postalcode_pickup"
          value={registerData.postalcode_pickup}
          onChange={handleInputChange}
          className="w-3/5 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <div className="text-sm font-[500] text-[#373737]">
          Please Tick ✔ if same as above Pick-up Address
        </div>
        <label className="inline-flex items-center mt-2 ml-4">
          <input
            type="checkbox"
            className="form-checkbox text-[#007AFF] h-4 w-4"
            name="same_pickupaddress"
            checked={registerData.same_pickupaddress}
            onChange={handleInputChange}
          />
          <span className="ml-2 text-[#151515] font-[500] text-sm">Same</span>
        </label>
      </div>
      <div className="mt-4">
        <label className="text-sm font-[500] text-[#373737]">
          Drop-off Address from School
          <span className="text-[#37373780]">
            {' '}
            (if different from residential home address)
          </span>
        </label>
        <input
          type="text"
          id="dropoff_address"
          name="dropoff_address"
          value={registerData.dropoff_address}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
          disabled={registerData.same_pickupaddress}
        />
      </div>
      <div className="flex flex-row justify-between mt-4">
        <label className="text-sm font-[500] text-[#373737] mt-2">
          Postal Code:
        </label>
        <input
          type="number"
          id="postalcode_dropoff"
          name="postalcode_dropoff"
          value={registerData.postalcode_dropoff}
          onChange={handleInputChange}
          disabled={registerData.same_pickupaddress}
          className="w-3/5 p-2 py-3 px-4 text-sm border rounded-xl border-[#E2E8F0] focus:outline-none"
        />
      </div>
    </div>
  );
};
ServicesForm.propTypes = {
  registerData: PropTypes.object.isRequired,
  setRegisterData: PropTypes.func.isRequired,
};

export default ServicesForm;
