import React from 'react';
import BackButton from '../components/Navbar/BackButton';
import Information from '../components/TermsConditions/Information';
import PrivacyPolicy from '../components/TermsConditions/PrivacyPolicy';
import PersonalData from '../components/TermsConditions/PersonalData';
import ButtonTerms from '../components/TermsConditions/ButtonTerms';
import { useSearchParams } from 'react-router-dom';

const TermsConditionsPage = () => {
  let [searchParams] = useSearchParams();
  const schoolId = searchParams.get('school_id') || null;

  return (
    <div className="font-sans">
      <div>
        <BackButton text="Terms & Conditions" to={`/?school_id=${schoolId}`} />
      </div>
      <div className="px-8">
        <Information />
      </div>
      <div className="px-6">
        <PrivacyPolicy />
        <PersonalData />
        <ButtonTerms />
        <div className="text-[#444342] mb-4 font-[400] text-sm  text-center mt-6">
          Powered by <span className="text-[#F15A29]"> i</span>Zee
          <span className="text-[#F15A29]">M</span> Pte Ltd
        </div>
      </div>
    </div>
  );
};

export default TermsConditionsPage;
