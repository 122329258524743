import React from 'react';

const PersonalData = () => {
  return (
    <div>
      <div className="font-[700] mt-4 text-sm"> PERSONAL DATA</div>
      <ol className="text-[#373737] text-sm font-[400]">
        <li>
          1. As used in this Notice: “customer” means an individual who (a) has
          contacted us through any means to find out more about any goods or
          services we provide, or (b) may, or has, entered into a contract with
          us for the supply of any goods or services by us; and “personal data”
          means data, whether true or not, about a customer who can be
          identified: (a) from that data; or (b) from that data and other
          information to which we have or are likely to have access.
        </li>
        <li className="mt-2">
          2. Depending on the nature of your interaction with us, some examples
          of personal data which we may collect from you include your name and
          identification information such as your NRIC number, contact
          information such as your address, email address or telephone number,
          nationality, gender, date of birth, marital status, and other
          audio-visual information, employment information and financial
          information such as credit card numbers, debit card numbers or bank
          account information.
        </li>
        <li className="mt-2">
          3. Other terms used in this Notice shall have the meanings given to
          them in the PDPA (where the context so permits).
        </li>
      </ol>
      <div className="font-[700] mt-2 text-sm">
        COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
      </div>
      <ol className="text-[#373737] text-sm font-[400]">
        <li>
          4. We generally do not collect your personal data unless (a) it is
          provided to us voluntarily by you directly or via a third party who
          has been duly authorised by you to disclose your personal data to us
          (your “authorised representative”) after (i) you (or your authorised
          representative) have been notified of the purposes for which the data
          is collected, and (ii) you (or your authorised representative) have
          provided written consent to the collection and usage of your personal
          data for those purposes, or (b) collection and use of personal data
          without consent is permitted or required by the PDPA or other laws. We
          shall seek your consent before collecting any additional personal data
          and before using your personal data for a purpose which has not been
          notified to you (except where permitted or authorised by law).
        </li>
        <li className="mt-2">
          5. We may collect and use your personal data for any or all of the
          following purposes:
          <ol>
            <li>
              (a) performing obligations in the course of or in connection with
              our provision of the goods and/or services requested by you;{' '}
            </li>
            <li>(b) verifying your identity;</li>
            <li>
              (c) responding to, handling, and processing queries, requests,
              applications, complaints, and feedback from you;{' '}
            </li>
            <li>(d) managing your relationship with us; </li>
            <li>(e) processing payment or credit transactions; </li>
            <li>
              (f) sending your marketing information about our goods or services
              including notifying you of our marketing events, initiatives,
              membership and rewards schemes;
            </li>
            <li>
              (g) complying with any applicable laws, regulations, codes of
              practice, guidelines, or rules, or to assist in law enforcement
              and investigations conducted by any governmental and/or regulatory
              authority;{' '}
            </li>
            <li>
              (h) any other purposes for which you have provided the
              information;
            </li>
            <li>
              (i) transmitting to any unaffiliated third parties including our
              third party service providers and agents, and relevant
              governmental and/or regulatory authorities, whether in Singapore
              or abroad, for the aforementioned purposes; and
            </li>
            <li>
              (j) any other incidental business purposes related to or in
              connection with the above.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          6. We may disclose your personal data:
          <ol>
            <li>
              (a) where such disclosure is required for performing obligations
              in the course of or in connection with our provision of the goods
              or services requested by you; or
            </li>
            <li>
              (b) to third party service providers, agents and other
              organisations we have engaged to perform any of the functions
              listed in clause 5 above for us.
            </li>
          </ol>
        </li>
        <div className="font-[700] mt-2 text-sm">WITHDRAWING YOUR CONSENT</div>
        <ol>
          <li>
            7. The consent that you provide for the collection, use and
            disclosure of your personal data will remain valid until such time
            it is being withdrawn by you in writing. You may withdraw consent
            and request us to stop using and/or disclosing your personal data
            for any or all of the purposes listed above by submitting your
            request in writing or via email to our Data Protection Officer at
            the contact details provided below.
          </li>
          <li>
            8. Upon receipt of your written request to withdraw your consent, we
            may require reasonable time (depending on the complexity of the
            request and its impact on our relationship with you) for your
            request to be processed and for us to notify you of the consequences
            of us acceding to the same, including any legal consequences which
            may affect your rights and liabilities to us. In general, we shall
            seek to process your request within ten (10) business days of
            receiving it.
          </li>
          <li>
            9. Whilst we respect your decision to withdraw your consent, please
            note that depending on the nature and scope of your request, we may
            not be in a position to continue providing our goods or services to
            you and we shall, in such circumstances, notify you before
            completing the processing of your request. Should you decide to
            cancel your withdrawal of consent, please inform us in writing in
            the manner described in clause 8 above.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">
          ACCESS TO AND CORRECTION OF PERSONAL DATA
        </div>
        <ol>
          <li>
            10. If you wish to make (a) an access request for access to a copy
            of the personal data which we hold about you or information about
            the ways in which we use or disclose your personal data, or (b) a
            correction request to correct or update any of your personal data
            which we hold about you, you may submit your request in writing or
            via email to our Data Protection Officer at the contact details
            provided below.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">
          PROTECTION OF PERSONAL DATA
        </div>
        <ol>
          <li>
            11. To safeguard your personal data from unauthorised access,
            collection, use, disclosure, copying, modification, disposal or
            similar risks, we have introduced appropriate administrative,
            physical and technical measures such as up-to-date antivirus
            protection, encryption and the use of privacy filters to secure all
            storage and transmission of personal data by us, and disclosing
            personal data both internally and to our authorised third party
            service providers and agents only on a need-to-know basis.
          </li>
          <li>
            12. You should be aware, however, that no method of transmission
            over the Internet or method of electronic storage is completely
            secure. While security cannot be guaranteed, we strive to protect
            the security of your information and are constantly reviewing and
            enhancing our information security measures.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">ACCURACY OF PERSONAL DATA</div>
        <ol>
          <li>
            13. We generally rely on personal data provided by you (or your
            authorised representative). In order to ensure that your personal
            data is current, complete and accurate, please update us if there
            are changes to your personal data by informing our Data Protection
            Officer in writing or via email at the contact details provided
            below.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">
          RETENTION OF PERSONAL DATA
        </div>
        <ol>
          <li>
            14. We may retain your personal data for as long as it is necessary
            to fulfil the purpose for which it was collected, or as required or
            permitted by applicable laws.
          </li>
          <li>
            15. We will cease to retain your personal data, or remove the means
            by which the data can be associated with you, as soon as it is
            reasonable to assume that such retention no longer serves the
            purpose for which the personal data was collected, and is no longer
            necessary for legal or business purposes.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">DATA PROTECTION OFFICER</div>
        <ol>
          <li>
            16. You may contact our Data Protection Officer if you have any
            enquiries or feedback on our personal data protection policies and
            procedures, or if you wish to make any request, in the following
            manner:
            <span className="text-[#FFCC00] font-[700]">
              [insert DPO’s contact details including, where applicable,
              address, email address and telephone number].
            </span>
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">
          EFFECT OF NOTICE AND CHANGES TO NOTICE
        </div>
        <ol>
          <li>
            17. This Notice applies in conjunction with any other notices,
            contractual clauses and consent clauses that apply in relation to
            the collection, use and disclosure of your personal data by us.
          </li>
        </ol>
        <div className="font-[700] mt-2 text-sm">
          ACKNOWLEDGEMENT AND CONSENT
        </div>
        <ol>
          <li>
            I acknowledge that I have read and understood the above Data
            Protection Notice, and consent to the collection, use and disclosure
            of my personal data by MIN YANG TRADING AND TRANSPORT SERVICES PTE
            LTD for the purposes set out in the Notice.
          </li>
        </ol>
      </ol>
      <div className="text-[0.6rem] mt-8">
        Copyright 2017 – Personal Data Protection Commission Singapore (PDPC)
      </div>
    </div>
  );
};

export default PersonalData;
